'use client';

import { useEffect, useRef } from 'react';
import useNetworkStatus from '../../hooks/useNetworkstatus';
import { useModal } from '../../context/ModalContext';
import { useTranslations } from 'next-intl';

const NetworkStatusChecker: React.FC = () => {
  const isOnline = useNetworkStatus();
  const { openModal } = useModal();
  const hasOpenedModal = useRef(false);
  const t = useTranslations();

  useEffect(() => {
    if (!isOnline && !hasOpenedModal.current) {
      hasOpenedModal.current = true; // 무한 루프 방지
      openModal({
        message01: t('modal.network_error01'),
        message02: t('modal.network_error02'),
        // icon: '/icons/network_error_icon.svg',
        primaryButtonText: t('modal.btn02'),
        secondaryButtonText: t('modal.btn01'),
        onClose: () => {
          hasOpenedModal.current = false;
          window.history.back();
        },
        onSecondaryAction: () => {
          hasOpenedModal.current = false;
          window.location.reload();
        },
      });
    }
  }, [isOnline, openModal, t]);

  return null;
};

export default NetworkStatusChecker;
