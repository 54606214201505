'use client';

import { createContext, useState, useContext, ReactNode } from 'react';
import Modal from '../components/modals/Modal';

interface ModalContextProps {
  isOpen: boolean;
  message01?: string;
  message02?: string;
  icon?: string;
  lottieAnimationData?: object; 
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onClose: () => void;
  onSecondaryAction?: () => void;
  onPrimaryAction?: () => void;
  openModal: (config: ModalConfig) => void;
}

interface ModalConfig {
  message01: string;
  message02?: string;
  icon?: string;
  lottieAnimationData?: object;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onClose: () => void;
  onSecondaryAction?: () => void;
  onPrimaryAction?: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);

  const openModal = (config: ModalConfig) => {
    setModalConfig(config);
  };

  const closeModal = () => {
    if (modalConfig?.onClose) {
      modalConfig.onClose();
    }
    setModalConfig(null);
  };

  const value = {
    isOpen: !!modalConfig,
    ...modalConfig,
    onClose: closeModal,
    openModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modalConfig && (
        <Modal
          message01={modalConfig.message01}
          message02={modalConfig.message02}
          icon={modalConfig.icon}
          lottieAnimationData={modalConfig.lottieAnimationData} 
          primaryButtonText={modalConfig.primaryButtonText}
          secondaryButtonText={modalConfig.secondaryButtonText}
          onClose={modalConfig.onClose}
          onSecondaryAction={modalConfig.onSecondaryAction}
        />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export { ModalContext };
