'use client';

import { useModal } from '../../context/ModalContext';
import Image from 'next/image';
import styles from './Modal.module.css';
import Lottie from 'react-lottie-player';
import { useState } from 'react';


const Modal: React.FC<ModalProps> = ({
  message01,
  message02,
  icon,
  lottieAnimationData,
  primaryButtonText,
  secondaryButtonText,
  onClose,
  onSecondaryAction,
  onPrimaryAction,
}) => {
  const { isOpen } = useModal();
  const [isStopped, setIsStopped] = useState(false);

  if (!isOpen) return null;

  const handlePrimaryAction = () => {
    if (onPrimaryAction) {
      onPrimaryAction();
    }
    onClose();
  };

  const handleSecondaryAction = () => {
    if (onSecondaryAction) {
      onSecondaryAction();
    }
    onClose();
  };

  return (
    <div className={styles.dim}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          {icon && !lottieAnimationData && (
              <Image src={icon} alt="modal icon" width={48} height={48} />
            )}
          {lottieAnimationData && (
            <Lottie
              className="modalLottie"
              play={!isStopped}
              animationData={lottieAnimationData}
              style={{ height: '48px', width: '48px' }}
              onComplete={()=> setIsStopped(true)}
            />
          )}
          <p>{message01}</p>
          <p>{message02}</p>
          <div className={styles.modalActions}>
            {onSecondaryAction && secondaryButtonText && (
              <button 
                onClick={handleSecondaryAction} 
                className={`${styles.modalButton} ${styles.secondary}`}
              >
                {secondaryButtonText}
              </button>
            )}
            {primaryButtonText && (
              <button 
                onClick={handlePrimaryAction} 
                className={styles.modalButton}
              >
                {primaryButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
