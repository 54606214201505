'use client';

import { ReactNode, useEffect, useState, createContext, useContext } from 'react';
import { IntlProvider } from 'next-intl';
import { ModalProvider } from '../context/ModalContext';
import NetworkStatusChecker from '../components/modals/NetworkStatusChecker';
import ErrorBoundary from './ErrorBoundary';
import { AuthProvider } from '../context/AuthContext';
import { useLoadingContext } from '../context/LoadingContext';
import ClientLoading from './ClientLoading';

interface ClientProvidersProps {
  children: ReactNode;
  locale: string;
  messages: any;
}

const useUserTimeZone = () => {
  const [timeZone, setTimeZone] = useState('UTC');

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    setTimeZone(userTimeZone);
  }, []);

  return timeZone;
};

const TimeZoneContext = createContext<string | undefined>(undefined);

const TimeZoneProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const timeZone = useUserTimeZone();
  return (
    <TimeZoneContext.Provider value={timeZone}>
      {children}
    </TimeZoneContext.Provider>
  );
};

export const useTimeZone = () => {
  const context = useContext(TimeZoneContext);
  if (context === undefined) {
    throw new Error('useTimeZone must be used within a TimeZoneProvider');
  }
  return context;
};

export const ClientProviders: React.FC<ClientProvidersProps> = ({ children, locale, messages }) => {
  const timeZone = useUserTimeZone();
  const { isLoading } = useLoadingContext();

  return (
    <IntlProvider locale={locale} messages={messages} timeZone={timeZone}>
      <ModalProvider>
        <AuthProvider>
          <TimeZoneProvider>
            <ErrorBoundary>
              {isLoading && <ClientLoading />}
              {children}
              <NetworkStatusChecker />
            </ErrorBoundary>
          </TimeZoneProvider>
        </AuthProvider>
      </ModalProvider>
    </IntlProvider>
  );
};
