/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import React, { Component, ErrorInfo, ReactNode } from 'react';
import { useModal } from '../context/ModalContext';
import { useTranslations } from 'next-intl';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ModalHandler />;
    }

    return this.props.children;
  }
}

const ModalHandler = () => {
  const { openModal } = useModal();
  const t = useTranslations('modal');

  React.useEffect(() => {
    openModal({
      message01: t('all_error'),
      icon: '/icons/error_icon.svg',
      primaryButtonText: t('btn_confirm'),
      onClose: () => {
        window.location.reload();
      },
    });
  }, [openModal, t]);

  return null;
};

export default ErrorBoundary;
